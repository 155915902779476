.main-content {
    display: flex;
    flex-direction: column;
}

.jumpto {
    background: #f0e7d8;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem 0;
    height: 100px;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    align-self: flex-start;
    z-index: 10;
    width: 100%;
}

.jumpto a {
    color: #ab9b96;
    position: relative;
}

.problem_wrapper {
    display: flex;
    flex-direction: column;
}

.image {
    position: static;
    height: 50vh;
    width: 90vw;
}

.image img {
    object-fit: cover;
    height: 100%;
    width: 100%;
}

.column {
    padding: 4rem 6rem 4rem 1.5rem;
    width: 100%;
    margin-left: auto;
    margin-right: 0;
}

.jumpto__target {
    margin-top: 6rem;
}

.banner {
    background: #f0e7d8;
    color: #ab9b96;
    padding: 4rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.banner__title {
    text-transform: uppercase;
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
    font-size: 24px;
    letter-spacing: 0.8px;
}

.banner__button {
    align-items: center;
    border-radius: 4px;
    background: #ab9b96;
    color: #f0e7d8;
    display: flex;
    justify-content: center;
    padding: 0.65rem 1.3rem;
    text-transform: uppercase;
    font-size: 12px;
}

.banner__button:hover {
    color: #f0e7d8;
}

.footer {
    background: #ab9b96;
    color: #f0e7d8;
    padding: 2rem;
    display: flex;
    align-content: center;
    justify-content: center;
}

@media screen and (min-width: 900px) {
    .problem_wrapper {
        flex-direction: row;
    }

    .column {
        width: 50%;
    }

    .image {
        height: calc(100vh - 100px);
        height: -moz-calc(100vh - 100px);
        height: -webkit-calc(100vh - 100px);
        height: -o-calc(100vh - 100px);
        width: 50vw;
        position: -webkit-sticky;
        position: sticky;
        top: 100px;
        align-self: flex-start;
    }
}