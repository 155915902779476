.invest-video {
  width: 616px;
  height: 347px;
  box-shadow: 0 0 5rem rgb(96, 172, 202, 0.35);
}
@media (max-width: 1280px) {
  .invest-video {
    width: 560px;
    height: 315px;
  }
}
@media (max-width: 780px) {
  .invest-video {
    width: 504px;
    height: 284px;
  }
}
@media (max-width: 780px) {
  .invest-video {
    width: 504px;
    height: 284px;
  }
}
@media (max-width: 780px) {
  .invest-video {
    width: 504px;
    height: 284px;
  }
}
@media (max-width: 600px) {
  .invest-video {
    width: 448px;
    height: 252px;
  }
}
@media (max-width: 500px) {
  .invest-video {
    width: 392px;
    height: 220px;
  }
}
@media (max-width: 420px) {
  .invest-video {
    width: 364px;
    height: 204px;
  }
}

.cbdc-text-invest {
  color: #00c6ae;
}
.cbdc-bg-invest {
  background-color: #00c6ae;
}
