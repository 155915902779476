.img-tabs {
    width: 100%;
    max-width: 600px;
    margin: 100px auto;
    background-color: #1a1a1a;
    border-radius: 5px;
    overflow: hidden;
  }

  .img-item-input {
    opacity: 0;
  }

  .img-item-label {
    cursor: pointer;
    background: #1a1a1a;
    color: #eee;
    padding: 1.5% 2%;
    float: left;
    font-size: 18px;
    border-top: 5px solid #1a1a1a;
  }
  @media (max-width: 600px) {
    .img-item-label {
      font-size: 12px;
    }
  }
  @media (max-width: 450px) {
    .img-item-label {
      font-size: 8px;
    }
  }

  .img-item-label:hover {
    background: #2a2a2a;

  }

  .img-item-input:checked + .img-item-label {
    border-top: 5px solid #1569bb;
  }

  .img-tabs .img-item-input:nth-of-type(1):checked ~ .img-panels .img-panel:first-child,
  .img-tabs .img-item-input:nth-of-type(2):checked ~ .img-panels .img-panel:nth-child(2),
  .img-tabs .img-item-input:nth-of-type(3):checked ~ .img-panels .img-panel:nth-child(3),
  .img-tabs .img-item-input:nth-of-type(4):checked ~ .img-panels .img-panel:last-child {
    opacity: 1;
    /* -webkit-transition: .3s; */
  }

  .img-panels {
    float: left;
    clear: both;
    position: relative;
    width: 100%;
    background: #000;
    min-height: 340px;
  }
  @media (max-width: 580px) {
    .img-panels {
      min-height: 280px;
    }
  }
  @media (max-width: 500px) {
    .img-panels {
      min-height: 240px;
    }
  }
  @media (max-width: 400px) {
    .img-panels {
      min-height: 200px;
    }
  }

        .img-panel {
            width: 100%;
            opacity: 0;
            position: absolute;
            /* padding: 4%; */
            box-sizing: border-box;
    font-family: Cascadian;
        }