.word-home {
  /* color: #1177e1; */
  background-image: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#1569bb),
    to(#00c6ae)
  );
  background-image: linear-gradient(to right, #1569bb, #00c6ae);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
}
.content-inner {
  max-height: 135px;
  overflow: hidden;
  transition: max-height 0.25s ease;
}

.read-more {
  display: inline-block;
  margin: 10px 0;
  cursor: pointer;
}

.globe-mobile {
  display: none;
}

@media (max-width: 1024px) {
  .globe-interactive {
    display: none;
  }
  .globe-mobile {
    display: block;
  }
}
