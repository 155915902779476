@tailwind base;
@tailwind components;
@tailwind utilities;
html {
  scroll-behavior: smooth;
}
body {
  margin: 0;
  background-color: #000;
  color: #f7f7f7;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.cbdc-text-blue {
  color: #1569bb;
}
.cbdc-bg-blue {
  background-color: #1569bb;
}
.cbdc-border-blue {
  border-left: 4px solid #1569bb;
}
.cbdc-text-green {
  color: #00c6ae;
}
.cbdc-bg-green {
  background-color: #00c6ae;
}
.cbdc-border-green {
  border: 3px solid #00c6ae;
}