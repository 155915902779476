.spinner-container {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%;
}
.blog-wrap {
	max-width: 1200px;
	margin: 0 auto;
}

.blog-wrap header {
	text-align: center;
}
.blod-description {
	font-size: 1.5rem;
}

.blog-date {
	font-size: 0.8rem;
	color: #a9a9a9;
	font-weight: 500;
}

.blog-wrap img {
	width: 100%;
}

.blog-subCategory {
	display: flex;
	justify-content: flex-start;
}
.blog-subCategory > div {
	margin: 1rem 0.5rem;
}
.blog-desc {
	padding: 1rem;
	margin-top: 1.5rem;
}

.icon-bar {
	position: fixed;
	top: 50%;
	left: 0;
	transform: translateY(-50%);
	-webkit-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	z-index: 10;
}
.icon-individual-top {
	border-top-right-radius: 4px;
}
.icon-individual-bottom {
	border-bottom-right-radius: 4px;
}
.icon-margin {
	margin-bottom: -7px;
}

.youtube-video-player {
	width: 1120px;
	height: 630px;
	border-radius: 9px;
	box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
	/* z-index: 10; */
}

@media (max-width: 1440px) {
	.youtube-video-player {
		width: 980px;
		height: 551px;
	}
}

@media (max-width: 1080px) {
	.youtube-video-player {
		width: 784px;
		height: 441px;
	}
}

@media (max-width: 800px) {
	.youtube-video-player {
		width: 644px;
		height: 362px;
	}
}

@media (max-width: 680px) {
	.youtube-video-player {
		width: 504px;
		height: 283px;
	}
	.icon-bar {
		top: 95%;
		display: flex;
		justify-content: center;
		left: 22%;
	}
	.blod-description {
		font-size: 1.2rem;
	}
}

@media (max-width: 550px) {
	.youtube-video-player {
		width: 448px;
		height: 252px;
	}
}

@media (max-width: 470px) {
	.youtube-video-player {
		width: 392px;
		height: 220px;
	}
}

@media (max-width: 390px) {
	.youtube-video-player {
		width: 364px;
		height: 204px;
	}
}
