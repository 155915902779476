section a {
	color: #1569bb;
}
.pri-heading {
	margin-top: 2em;
	margin-bottom: 1em;
	text-align: center;
	font-weight: bold;
	font-size: xx-large;
	color: #1569bb;
}
.cookie-line {
	height: 1px;
	box-sizing: border-box;
	background-color: rgb(206, 206, 206);
	max-width: 700px;
	margin: auto;
	margin-bottom: 2em;
}
.cookie-term {
	width: 700px;
	margin: auto;
	text-align: left;
	padding-bottom: 6em;
}
.cookie-term-list {
	margin: 0.5em;
	margin-left: 2em;
	text-indent: -1.3em;
}
.cookie-term-para {
	margin-top: 1em;
}
.cookie-term-heading {
	padding-top: 2em;
	font-weight: bold;
	font-size: x-large;
	color: #1569bb;
}
terms {
	text-align: center;
	justify-content: center;
}
@media (max-width: 1024px) {
	.content-term {
		width: 600px;
		margin: auto;
	}
}
@media (max-width: 640px) {
	.cookie-term {
		width: 340px;
		margin: auto;
	}
	.cookie-term-para {
		font-size: small;
	}
	.cookie-term-heading {
		font-size: medium;
	}
	.tittle h1 {
		font-size: x-large;
	}
	.tittle .pri-heading {
		font-size: x-large;
	}
	.line {
		max-width: 300px;
	}
	li {
		font-size: small;
	}
	.cookie-term-list {
		font-size: small;
	}
	.tittle {
		margin: auto;
		width: 300px;
	}
}
