.profile-card {
    background-color: rgb(0, 0, 0, 0.6);
    opacity: 0;
    transition: all 0.5s;
    backdrop-filter: blur(15px);
    -webkit-backdrop-filter: blur(15px);
    border: 1px solid #c1c1c1;
}
.profile-card:hover {
    opacity: 1;
}

.linkedin-logo {
    position: absolute;
    bottom: 10px;
    right: 10px;
    z-index: 1;
}

.profile-background {
    background-color: #17191E;
    border: 1px solid #17191E;
} 